const estimates = {
    "화면 구현사항": [
        {
            id: 1,
            name: "20페이지 이하",
            description: "화면 전체구현 사항이 20페이지 이내의 서비스일 경우 추천드립니다.",
            price: 4000000
        },
        {
            id: 1,
            name: "30페이지 이하",
            description: "화면 전체구현 사항이 30페이지 이내의 서비스일 경우 추천드립니다.",
            price: 6000000
        },
        {
            id: 1,
            name: "40페이지 이하",
            description: "화면 전체구현 사항이 40페이지 이내의 서비스일 경우 추천드립니다.",
            price: 8000000
        },
    ],
    "인증 및 회원정보": [
        {
            id: 1,
            name: "일반 로그인 및 회원가입",
            description: "플랫폼 자체에서 요구하는 로그인 입니다.",
            price: 1000000
            
        },
        {
            id: 2,
            name: "소셜 로그인 및 회원가입",
            description: "소셜(카카오톡, 네이버, 구글)을 통해 서비스를 이용할 수 있도록 하는 기능입니다. 사용자에게 편의성을 제공하여 가입 유도를 보다 수월히 진행할 수 있습니다",
            price: 1500000
        },
        {
            id: 3,
            name: "이메일 인증",
            description: "고객 이메일의 소유 여부를 엄격하게 검증하기 위한 기능입니다",
            price: 500000
        },
        {
            id: 3,
            name: "휴대전화번호 인증",
            description: "고객의 휴대전화 소유 여부를 엄격하게 검증하기 위한 기능입니다",
            price: 500000
        },
        {
            id: 3,
            name: "실명 인증",
            description: "-",
            price: 0
        },
        {
            id: 3,
            name: "아이디 또는 비밀번호 찾기",
            description: "고객이 아이디나 비밀번호를 분실하였을 경우 인증을 통해 관리자의 도움 없이도 스스로 찾을 수 있는 기능입니다",
            price: 500000
        },
        {
            id: 3,
            name: "회원정보 수집(기본)",
            description: "고객 정보를 파악하기 위해서 회원정보를 수집하는 기능입니다. 수집항목은 이름, 닉네임, 성별, 내외국인, 생년월일입니다",
            price: 500000
        },
        {
            id: 3,
            name: "회원정보 수집(복잡)",
            description: "기본적인 회원정보를 제외한 경력 또는 이력사항 등 복잡한 형태로 수집되거나 엄격하게 처리해야 하는 회원정보의 경우 요구되는 기능입니다",
            price: 1000000
        },
        {
            id: 3,
            name: "프로필 이미지 등록",
            description: "고객의 프로필 사진이 요구될 경우 필요한 기능입니다. 추가적인 파일 서버의 구축 및 파일 업로드하는 로직이 필요합니다.",
            price: 1000000
        },
        {
            id: 3,
            name: "마이페이지 및 회원정보 수정",
            description: "고객이 기본정보를 확인할 수 있으며 이에 대한 지속적인 수정이 필요한 경우 요구되는 기능입니다.",
            price: 1500000
        },
    ],
    "알림": [
        {
            id: 1,
            name: "문자 알림",
            description: "플랫폼 자체에서 요구하는 로그인 입니다.",
            price: 1000000
        },
        {
            id: 1,
            name: "이메일 알림",
            description: "플랫폼 자체에서 요구하는 로그인 입니다.",
            price: 1000000
        },
        {
            id: 1,
            name: "카카오톡 알림",
            description: "플랫폼 자체에서 요구하는 로그인 입니다.",
            price: 1000000
        },
        {
            id: 1,
            name: "푸쉬 알림",
            description: "플랫폼 자체에서 요구하는 로그인 입니다.",
            price: 1000000
        },
    ],
    "소셜 및 커뮤니케이션": [
        {
            id: 1,
            name: "쪽지",
            description: "플랫폼 자체에서 요구하는 로그인 입니다.",
            price: 1000000
        },
        {
            id: 1,
            name: "실시간 채팅",
            description: "플랫폼 자체에서 요구하는 로그인 입니다.",
            price: 1000000
        },
        {
            id: 1,
            name: "팔로잉 또는 친구",
            description: "플랫폼 자체에서 요구하는 로그인 입니다.",
            price: 1000000
        },
        {
            id: 1,
            name: "친구추가 허용 및 권한",
            description: "플랫폼 자체에서 요구하는 로그인 입니다.",
            price: 1000000
        },
    ],
}


export default estimates