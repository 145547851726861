import { formatNumberWithCommas } from "../../utils/stringFormatter";

const PriceItem = ({item}) => {
    return (
        <div
            style={{
                background: 'white',
                aspectRatio: '3/2',
                borderRadius: '6px',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                display: 'flex',
                flexDirection: 'column',
                padding: '18px',

            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        flex: '1'
                    }}
                >
                    <span
                        style={{
                            fontFamily: 'PretendardBold'
                        }}
                    >
                        {item.name}
                    </span>
                </div>

                <div
                    style={{
                        width: '16px',
                        height: '16px',
                        border: '2px solid #888888',
                        borderRadius: '4px',
                    }}
                >

                </div>

            </div>


            
            <span
                style={{
                    marginTop: '8px',
                    fontFamily: "PretendardMedium",
                    color: '#888888'
                }}
            >
                
                + {formatNumberWithCommas(item.price)}원
            </span>

            <span
                style={{
                    marginTop: '16px',
                    fontFamily: "PretendardRegular",
                    color: '#444444',
                    fontSize: '14px',
                    lineHeight: '1.3',
                    letterSpacing: '0.2px',
                }}
            >
            
                {item.description}
            </span>
        </div>
    )

}

export default PriceItem;