import { BiMobile, BiPhone } from "react-icons/bi"
import { SamplePage } from "."
import PriceSection from "../../components/price/PriceSection"
import estimates from "../../database/estimates"

const Presenter = () => {
    return (
        <div
            style={{
                width: '100vw',
                background: '#F5F5F5',
                height: '100dvh',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    height: '100%',
                }}
            >
                <div
                    style={{
                        width: '80%',
                        height: '100%',
                        // border: '1px solid black',
                        background: '#F5F5F5',
                        position: 'relative',
                    }}
                >
                    
                    {/* 타이틀 항목 */}
                    <div
                        style={{
                            background: 'white',
                            padding:'8px 12px',
                            display: 'flex',
                            alignItems: 'center',
                            
                        }}
                    >
                        <div
                            style={{
                                // border: '1px solid black',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <BiMobile size={18}/>
                        </div>
                        
                        <span
                            style={{
                                marginLeft: '2px',
                                fontSize: '14px',
                                fontFamily: "PretendardSemoBold",
                                textAlign: 'center',
                                verticalAlign: 'center',
                            }}
                        >
                            견적 항목
                        </span>

                    </div>


                    <div
                        style={{
                            background: '#F5F5F5',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '8px 12px',
                            overflowY: 'scroll',
                            height: '100dvh',
                        }}
                    >
                        <PriceSection title="화면 구현사항" itemList={estimates['화면 구현사항']}/>
                        <PriceSection title="인증 및 회원정보" itemList={estimates['인증 및 회원정보']}/>
                        <PriceSection title="알림" itemList={estimates['알림']}/>
                        <PriceSection title="소셜 및 커뮤니케이션" itemList={estimates['소셜 및 커뮤니케이션']}/>
                    </div>


                </div>
                <div
                    style={{
                        width: '20%',
                        borderLeft: '1px solid #DEDEDE',
                        background: '#F5F5F5',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    {/* 타이틀 항목 */}
                    <div
                        style={{
                            background: 'white',
                            padding:'8px 12px',
                            display: 'flex',
                            alignItems: 'center',
                            
                        }}
                    >
                        <div
                            style={{
                                // border: '1px solid black',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <BiMobile size={18}/>
                        </div>
                        
                        <span
                            style={{
                                marginLeft: '2px',
                                fontSize: '14px',
                                fontFamily: "PretendardSemoBold",
                                textAlign: 'center',
                                verticalAlign: 'center',
                            }}
                        >
                            모바일 서비스
                        </span>

                    </div>

                    {/* 서비스 뷰어 */}
                    <div
                        style={{
                            background: '#F5F5F5',
                            height: 'auto',
                            flex: '1',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <span
                            style={{
                                marginBottom: '12px',
                                fontSize: '14px',
                                fontFamily: "PretendardBold",
                                textAlign: 'center',
                                verticalAlign: 'center',
                            }}
                        >
                            서비스 화면
                        </span>

                        <div
                            style={{
                                width: 'calc(100% - 24px)', // 좌우 패딩을 제외한 너비
                                margin: '0 auto', // 중앙 정렬
                                background: 'white',
                                aspectRatio: '375/812'
                            }}
                        >
                            {/* 자식 요소 내용 */}
                        </div>

                    </div>
                    
                    {/* <div
                        style={{
                            background: 'white',
                            // width: '375px',
                            // height: '812px'
                            aspectRatio: '375/812'
                        }}
                    >
                        <span>
                            ABCD
                        </span>
                    </div> */}

                </div>
            </div>
        
        </div>
    )
}

export default Presenter