import PriceItem from "./PriceItem";

const PriceSection = ({title, itemList}) => {
    return (
        <>  
            <span
                style={{
                    fontFamily: 'PretendardBold',
                    marginBottom: '24px',
                    marginTop: '36px',
                    fontSize: '20px',
                }}
            >
                {title}
            </span>

            <div
                style={{
                    display: 'grid',
                    // border: '1px solid black',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gap: '14px',
                }}
            >
                {
                    [...itemList].map((item, index) => {
                        return (
                            <PriceItem item={item}/>
                        )
                    })
                }


            </div>    

            <div style={{height: '32px'}} />
        
        </>
    )
}

export default PriceSection;