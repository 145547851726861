import "./App.css";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SamplePage } from "./pages/Sample";

function App() {
  return (
    <Router>
      <Routes>
        {/* 
        <Route path="/registration" element={<RegistrationPage />} />
        <Route path="/profile/basic" element={<ProfileBasicPage />} />
        <Route path="/profile/image" element={<ProfileImagePage />} />
        <Route path="/mypage" element={<MyProfilePage />} />
        <Route path="/complete" element={<CompletePage />} /> 
        */}
        <Route path="/" element={<SamplePage/>} />
      </Routes>
    </Router>
  );
}

export default App;
